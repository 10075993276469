<template>
    <div>
        <h3>Notice</h3>
        <v-btn
            text
            @click="showDialog"
            outlined
            class="btn btn-lg btn-add-main"
            style="background:transparent;"
        >
            <i class="fas fa-plus-circle"></i> Add Notice
        </v-btn>

        <v-btn
            text
            :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
            outlined
            class="btn btn-lg btn-secondary-main"
            style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <v-app>
            <div class="row mt-2">
                <div class="col-xl-12">
                    <KTPortlet v-bind:title="''">
                        <template v-slot:body>
                            <div class="row" v-if="loading">
                                <div class="col-md-12">
                                    <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                                </div>
                            </div>
                          <div class="row" v-else-if="!loading && notices && notices.length<1">
                            <div class="col-md-12 text-center">
                             <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                              <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                            </div>
                          </div>
                            <div class="row" v-else>
                                <b-table
                                    hover
                                    responsive
                                    :items="notices"
                                    id="my-table"
                                    :busy.sync="isBusy"
                                    :fields="fields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                >
                                    <template v-slot:table-busy>
                                        <div class="text-center text-info my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading Pages...</strong>
                                        </div>
                                    </template>
                                    <template v-slot:cell(title)="data">
                                        <span v-if="data.item.title" >{{data.item.title | truncate(20, '...')}}</span>
                                        <span v-else>N/A</span>
                                    </template>
                                    <template v-slot:cell(link)="data">
                                        <span v-if="data.item.link" >{{data.item.link | truncate(20, '...')}}</span>
                                        <span v-else>N/A</span>
                                    </template>
                                    <template v-slot:cell(description)="data">
                                        <span v-if="data.item.description" >{{data.item.description | truncate(20, '...')}}</span>
                                        <span v-else>N/A</span>
                                    </template>
                                    <template v-slot:cell(is_active)="data">
                                        <i
                                            v-if="data.item.is_active"
                                            class="kt-nav__link-icon flaticon2-check-mark text-success"
                                        ></i>

                                        <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                                    </template>

                                    <!-- A virtual composite column -->
                                    <template v-slot:cell(action)="data">
                                        <div class="kt-widget__toolbar">
                                            <a
                                                href="#"
                                                class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                                data-toggle="dropdown"
                                            >
                                                <i class="flaticon-more-1"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                <ul class="kt-nav">
                                                    <li class="kt-nav__item">
                                                        <a href="#" @click="editNotice(data.item)" class="kt-nav__link">
                                                            <i class="kt-nav__link-icon flaticon-edit"></i>
                                                            <span class="kt-nav__link-text">Edit</span>
                                                        </a>
                                                    </li>
                                                    <li class="kt-nav__item">
                                                        <a href="#" class="kt-nav__link"
                                                           @click="deleteNotice(data.item.id)">
                                                            <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>
                                                            <span class="kt-nav__link-text">Delete</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                </b-table>
                                <div class="col-md-12 text-right">
                                    <b-pagination
                                            v-if="rows>0"
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                        aria-controls="my-table"
                                        class="pull-right"
                                    ></b-pagination>
                                </div>
                            </div>
                        </template>
                    </KTPortlet>
                </div>
            </div>
            <v-dialog scrollable v-model="dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Add Notice</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <div class="form-block">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field label="Title" v-model="notice.title" required densed
                                                      outlined></v-text-field>
                                        <p
                                            class="text-danger font-weight-bold"
                                            v-if="$v.notice.title.$error"
                                        >Title is required</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-textarea
                                            outlined
                                            dense
                                            label="Description"
                                            v-model="notice.description"
                                            required
                                        ></v-textarea>
                                        <p
                                            class="text-danger font-weight-bold"
                                            v-if="$v.notice.description.$error"
                                        > Description is required</p>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-text-field outlined dense label="Link" v-model="notice.link"></v-text-field>
                                      <p
                                          class="text-danger font-weight-bold"
                                          v-if="$v.notice.link.$error"
                                      >Link must be a valid url</p>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <v-switch v-model="notice.is_active" label="Active"></v-switch>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                        <v-btn
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            @click.prevent="saveNotice"
                            :loading="isBusy"
                        >{{ edit ? 'Update' : 'Save' }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required, url} from "vuelidate/lib/validators";
    import WebsiteNotice from "@/services/Websites/WebsiteNoticeService";

    const WebsiteNoticeService = new WebsiteNotice();

    export default {
        name: "website-pages",
        components: {
            KTPortlet
        },

        validations() {
            return {
                notice: {
                    title: {required},
                    description: {required},
                  link: {url},
                }
            };
        },

        data() {
            return {
                isBusy: false,
                loading: true,
                dialog: false,
                rules: [
                    value =>
                        !value ||
                        value.size < 2000000 ||
                        "Avatar size should be less than 2 MB!"
                ],
                fields: [
                    {key: "title", sortable: true},
                    {key: "description", label: "Description", sortable: false},
                    {key: "link", label: "Link", sortable: false},
                    {key: "is_active", label: "Published ", sortable: false},
                    {key: "action", sortable: false}
                ],
                edit: false,
                notice: {
                    title: null,
                    description: null,
                    link: null,
                    is_active: true
                },
                notices: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15]
            };
        },
        methods: {
            showDialog() {
                this.dialog = true;
            },

            closeDialog() {
                this.dialog = false;
                this.$v.$reset();
            },
            editNotice(item) {
                this.notice = item;
                this.edit = true;
                this.showDialog();
            },
            deleteNotice(id) {
                this.$confirm(
                    {
                        message: `Are you sure you want to permanently delete this notice?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteNoticeService.delete(this.siteUrl, id).then(res => {
                                    this.$snotify.success("Notice deleted");
                                    this.getNotices();
                                });
                            }
                        }
                    }
                )

            },
            getNotices() {
                WebsiteNoticeService.paginate(this.siteUrl)
                    .then(res => {
                        this.notices = res.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                        this.loading = false;
                    });
            },
            saveNotice() {
                this.$v.$touch();
                if (!this.$v.notice.$error) {
                    if (this.edit) {
                        this.updateNotice();
                    } else this.createNotice();
                }
            },
            updateNotice() {
                this.isBusy = true;
                WebsiteNoticeService.update(this.siteUrl, this.notice.id, this.notice)
                    .then(res => {
                        this.$snotify.success("Notice updated");
                        this.reset();
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },
            reset() {
                this.dialog = false;
                this.isBusy = false;
                this.edit = false;
                this.notice = {};
                this.$v.$reset();
                this.getNotices();
                tei
            },
            createNotice() {
                this.isBusy = true;
                WebsiteNoticeService.create(this.siteUrl, this.notice)
                    .then(res => {
                        this.$snotify.success("Notice added");
                        this.reset();
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },

            toggleBusy() {
                this.isBusy = !this.isBusy;
            }
        },

        computed: {
            rows() {
                return this.notices.length;
            },
            siteUrl() {
                return this.$route.params.domainname;
            },
            generateSlug() {
                return this.slugify(this.page.title);
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Manage Pages", route: "/websites"}
            ]);
            this.getNotices();
            // this.getBlogCategory();
        }
    };
</script>
<style scoped>
    #preview {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #preview img {
        max-width: 100%;
        max-height: 350px;
    }
</style>
